<template>
  <div class="employees">
    <!-- <modal v-show="isVisible" @close="sendFormInfo">
      <template v-slot:header>Ажилтан нэмэх</template>
      <template v-slot:body>
        <add-employee />
      </template>
    </modal> -->
    <div class="flex justify-between mb-2">
      <div class="ml-2 text-xl">Ажилтан</div>
      <button
        class="button flex items-center max-w-max px-4 mr-2 font-normal"
        @click="addEmployee"
      >
        <!-- <font-awesome-icon :icon="['fas', 'plus-square']" class="text-lg mr-4" /> -->
        <div class="mr-4">
          <div class="plus">
            <div>+</div>
          </div>
        </div>
        <span>Ажилтан нэмэх</span>
      </button>
    </div>
    <div class="wrapper">
      <div class="wrapper2">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>email</th>
              <th>Нэр</th>
              <th>Овог</th>
              <th>Төрсөн өдөр</th>
              <th>Эрх</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>
                <input type="text" v-model="filterFName" class="filter" />
              </td>
              <td>
                <input type="text" v-model="filterLName" class="filter" />
              </td>
              <td><input type="text" v-model="filterBday" class="filter" /></td>
              <td>
                <select v-model="filterRole" class="filter">
                  <!-- <option value="" selected disabled hidden >Сонгох</option> -->
                  <option value="">бүгд</option>
                  <option value="admin">admin</option>
                  <option value="editor">editor</option>
                  <option value="viewer">viewer</option>
                </select>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr
              v-for="employee in filteredEmployees"
              :key="employee.employeeId"
            >
              <!-- v-model="this.$store.state.employees.employeeIds" -->
              <td class="w-2 sticky left-0 bg-blue-50">
                <input
                  :value="employee.useraccount_id"
                  v-model="employeeIds"
                  type="checkbox"
                />
              </td>
              <td>{{ employee.email }}</td>
              <td>{{ employee.firstname }}</td>
              <td>{{ employee.lastname }}</td>
              <td>{{ employee.birthday }}</td>
              <td>{{ employee.roleid }}</td>
              <td>
                <font-awesome-icon
                  :icon="['fas', 'edit']"
                  @click="setEmployeeDetail(employee.useraccount_id)"
                  class="cursor-pointer"
                >
                </font-awesome-icon>
              </td>
              <td>
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  @click="removeEmployees([employee.useraccount_id])"
                  class="cursor-pointer text-orange-600"
                >
                </font-awesome-icon>
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>

       </tfoot> -->
        </table>
      </div>
    </div>
    <div>{{ filterFName }}</div>

    <button
      @click="removeEmployees(employeeIds)"
      :class="[
        ' m-2 px-4 max-w-min',
        employeeIds.length !== 0 ? 'button-delete' : 'button-passive',
      ]"
    >
      Устгах
    </button>
    <div v-show="employeeIds.length !== 0">{{ employeeIds }}</div>
  </div>
</template>

<script>
// import Modal from "../../assets/Modal.vue";
// import AddEmployee from "./AddEmployee.vue";
export default {
  data: () => {
    return {
      filterFName: "",
      filterLName: "",
      filterBday: null,
      filterRole: null,
      filtered: [],
      isVisible: false,
    };
  },
  components: {
    // Modal,
    // AddEmployee,
  },
  props: ["employees", "removeEmployees", "setEmployeeDetail", "addEmployee"],
  methods: {
    ids() {
      this.$store.dispatch("employees/ids", { employeeIds: this.employeeIds });
    },
    openForm() {
      // this.isVisible = true;
      this.$router.push({ name: "add-employee" });
    },
    sendFormInfo() {
      this.isVisible = false;
    },
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((el) => {
        if (this.filterLName === "" && this.filterFName === "") {
          return el;
        } else {
          if (el.lastname !== null && el.firstname !== null) {
            return (
              el.firstname
                .toLowerCase()
                .match(this.filterFName.toLowerCase()) &&
              el.lastname.toLowerCase().match(this.filterLName.toLowerCase())
              // el.bithday.include(this.filterBday) &&
              // el.roleid.include(this.filterRole)
            );
          }
        }
        // if (el.lastname === null) {
        //   console.log("nulllll" + el.lastname);
        //   return el;
        // } else {
        //   console.log(el.firstname);
        //   return (
        //     // el.firstname.toLowerCase().match(this.filterFName.toLowerCase()) &&
        //     el.lastname.toLowerCase().match(this.filterLName.toLowerCase())
        //     // el.bithday.include(this.filterBday) &&
        //     // el.roleid.include(this.filterRole)
        //   );
        // }
      });
    },
    employeeIds: {
      get() {
        return this.$store.state.employees.employeeIds;
      },
      set(value) {
        console.log(value);
        this.$store.commit("employees/ids", value);
      },
    },
  },
};
</script>

<style  lang="postcss" scoped>
.employees {
  @apply w-full;
}
.plus {
  @apply flex items-center justify-center rounded-sm w-5 h-5 border-2 border-white;
}
.plus div {
  @apply text-lg font-semibold leading-relaxed;
}
.wrapper {
  @apply table table-fixed w-full;
}
.wrapper2 {
  @apply table-cell overflow-x-auto w-full;
}
table {
  @apply w-full border-collapse;
}
/* table tbody thead {
  @apply block overflow-auto
} */
tr {
  @apply border-b-2 border-gray-200 px-2 text-gray-700;
}

td {
  @apply px-2;
}

thead {
  @apply border-b-2 border-gray-200 text-blue-700;
}
th {
  @apply text-blue-700 bg-blue-100;
}
.filter {
  @apply w-full px-2 py-1 my-1 focus:ring-transparent focus:outline-none;
}
</style>