<template>
  <div>
    <div>
      <employees-list
        :employees="this.$store.state.employees.employees"
        :removeEmployees="removeEmployees"
        :setEmployeeDetail="setEmployeeDetail"
        :addEmployee="addEmployee"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { router } from "../../../main";

import EmployeesList from "./EmployeesList.vue";
export default {
  components: { EmployeesList },
  data: () => {
    return {
      employees: [],
      // employee: {},
    };
  },
  methods: {
    removeEmployees(employees) {
      this.$store.dispatch("employees/removeEmployees", employees);
    },
    setEmployeeDetail(eid) {
      // console.log(eid);
      const path = `/employees/${eid}/profile`;
      if (this.$route.path !== path) {
        router.push({ name: "employee-detail", params: { e_id: eid } });
      }
    },
    addEmployee() {
      this.$router.push({ name: "add-employee" });
    },
  },
  created() {
    this.$store.dispatch("employees/getEmployees");
    // this.employees = this.$store.state.employees.employees;
  },
};
</script>

<style>
</style>